export const menuItems = {

    // header menu items
    header: [
      {
        label: "Home",
        to: "/",
        dropdown: false,
      },
      {
        label: "About",
        to: "",
        dropdown: true,
        items: [
          { label: "Promise of the ARC APAC Summit", to: "/promise" },
          { label: "Host: ARC Innovation", to: "/host" },
          { label: "Committee", to: "/committee" },
          { label: "Latest News", to: "/latest-news" },
        ],
      },
      {
        label: "Registration",
        to: "/registration",
        dropdown: false,
      },
      {
        label: "Accommodation",
        to: "/accommodation",
        dropdown: false,
      },
      {
        label: "Program",
        to: "",
        dropdown: true,
        items: [
          { label: "Themes", to: "/themes" },
          { label: "Speakers", to: "/speakers" },
        ],
      },
      {
        label: "Destination",
        to: "",
        dropdown: true,
        items: [
          { label: "Destination", to: "/destination" },
          { label: "Venue", to: "/venue" },
          { label: "Visa Information", to: "/visa-information" },
        ],
      },
      {
        label: "Partnerships",
        to: "",
        dropdown: true,
        items: [
          { label: "Partnerships", to: "/partnerships" },
          { label: "Partners", to: "/partners" },
        ],
      },
      // {
      //   label: "Latest News",
      //   to: "/latest-news",
      //   dropdown: false,
      // },
      {
        label: "Contact Us",
        to: "/contact",
        dropdown: false,
      },
    ],

    // footer menu items
    footer: [
      {
        title: "About",
        links: [
          { name: "Promise of the ARC APAC Summit", to: "/promise" },
          { name: "Host: ARC Innovation", to: "/host" },
          { name: "Committee", to: "/committee" },
        ],
      },
      {
        title: "Program",
        links: [
          { name: "Preliminary Program", to: "/preliminary-program" },
          { name: "Registration", to: "/registration" },
          // { name: "Templates", to: "#" },
          { name: "Themes", to: "/themes" },
        ],
      },
      {
        title: "Destination",
        links: [
          { name: "Destination", to: "/destination" },
          { name: "Venue", to: "/venue" },
          { name: "Visa Information", to: "/visa-information" },
          { name: "Accommodation", to: "/accommodation" },
        ],
      },
      {
        title: "Contact",
        links: [
          { name: "Contact Us", to: "/contact" },
          { name: "Partnerships", to: "/partnerships" },
        ],
      },
    ],
  };
  