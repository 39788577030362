exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodation-js": () => import("./../../../src/pages/accommodation.js" /* webpackChunkName: "component---src-pages-accommodation-js" */),
  "component---src-pages-committee-js": () => import("./../../../src/pages/committee.js" /* webpackChunkName: "component---src-pages-committee-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-destination-js": () => import("./../../../src/pages/destination.js" /* webpackChunkName: "component---src-pages-destination-js" */),
  "component---src-pages-host-js": () => import("./../../../src/pages/host.js" /* webpackChunkName: "component---src-pages-host-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-news-js": () => import("./../../../src/pages/latest-news.js" /* webpackChunkName: "component---src-pages-latest-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-preliminary-program-js": () => import("./../../../src/pages/preliminary-program.js" /* webpackChunkName: "component---src-pages-preliminary-program-js" */),
  "component---src-pages-promise-js": () => import("./../../../src/pages/promise.js" /* webpackChunkName: "component---src-pages-promise-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-themes-js": () => import("./../../../src/pages/themes.js" /* webpackChunkName: "component---src-pages-themes-js" */),
  "component---src-pages-venue-js": () => import("./../../../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */),
  "component---src-pages-visa-information-js": () => import("./../../../src/pages/visa-information.js" /* webpackChunkName: "component---src-pages-visa-information-js" */)
}

