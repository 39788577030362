const sponsors = [
    { 
        name: "Sectra", 
        website: "https://sectra.com/", 
        bio: "Sectra is a leading provider of medical imaging IT and cybersecurity solutions with a vision to create a healthier and safer society. With over 2,500 global installations, Sectra prioritizes customer value and satisfaction, equipping healthcare organisations with advanced enterprise imaging solutions that enhance productivity and support positive patient outcomes.", 
        order: 1 },
    { 
        name: "Silverchain", 
        website: "https://silverchain.org.au/", 
        bio: "Silverchain Group is one of Australia’s leading home care specialists, providing health and aged care services to more than 140,000 clients each year. Trusted by Australians to deliver care that is differentiated by quality and safety for 130 years, we aim to revolutionise the way care is provided and to transform the way it is received. We provide complex and acute nursing; hospital in the home; specialist community palliative care; home help, support to live independently and wellbeing services; allied health services; digital enabled care and remote health monitoring; and chronic disease management.", 
        order: 1 },
    { 
        name: "Square Peg", 
        website: "https://www.squarepeg.vc/", 
        bio: "Square Peg is a venture capital firm that backs founders from three of technology’s most exciting regions: Australia & New Zealand, Israel, and Southeast Asia. Founded in 2012 in Australia, Square Peg combines the best of a local partner with a global network of companies and now backs founders early and repeatedly from three of technology’s most exciting regions: Australia & New Zealand, Israel, and Southeast Asia. Five funds in with US$2.9 billion in assets across its venture capital and public equities funds, Square Peg is the only investor that backed Canva, Airwallex, and Rokt, three of Australia’s highest-valued private tech companies, and is honoured to partner with Fiverr, Aidoc, and Tomorrow.io in Israel and Doctor Anywhere and Kredivo out of Singapore. Learn more at <a href='https://www.squarepeg.vc/' target='_blank' rel='noopener noreferrer'>squarepeg.vc</a>", 
        order: 1 },
    { 
        name: "ASME", 
        website: "https://www.asme.org.au/", 
        order: 1 },
    { 
        name: "MCB", 
        website: "https://www.melbournecb.com.au/", 
        order: 1 },
    { 
        name: "Melbourne-Australia", 
        website: "https://www.vic.gov.au/", 
        order: 2 },
];

export default sponsors;